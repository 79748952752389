import React from 'react';
import CONFIG from '../config-APP_TARGET';
import * as constants from './constants';

function COMM(route, type, set = {}, config = {})
{
    return async (dispatch, getState) => {
        config = Object.assign({
            sid: true,
            did: false
        }, config);

        let sid;
        const user = getState().user;
        if(config.sid)
        {
            sid = user?.sid;
            if(!sid)
                return dispatch({ type: constants.LOGOUT_SUCCESS });
        }
        const did = user?.did;

        const constructHeaders = config => {
            const user = getState().user;
            const headers = config.headers || {};
            config.sid && user && user.sid && (headers[constants.HEADER_SID] = user.sid);
            config.did && user && user.did && (headers[constants.HEADER_DID] = user.did);
            return headers;
        };

        const call = () => new Promise((resolve, reject) => {
            if(!set) set = {};
            if(!set.method) set.method = 'POST'; // force POST if nothing is set
            if(!set.url) set.url = CONFIG.api + route;
            if(!set.dataType) set.dataType = 'json'; // force JSON if nothing is set

            if(!set.headers) set.headers = constructHeaders(config);

            set.crossDomain = true;
            set.success = function(data, textStatus, jqXHR){
                dispatch({
                    type: type + constants.SUCCESS,
                    payload: data,
                    meta: {
                        headers: {
                            [constants.HEADER_ERROR]: jqXHR.getResponseHeader(constants.HEADER_ERROR),
                            [constants.HEADER_SID]: jqXHR.getResponseHeader(constants.HEADER_SID),
                            [constants.HEADER_DID]: jqXHR.getResponseHeader(constants.HEADER_DID),
                        }
                    }
                });
                resolve({
                    payload: data,
                    status: jqXHR.status
                });
            };
            set.error = function(jqXHR, textStatus, errorThrown){
                dispatch({
                    type: type + constants.FAILURE,
                    payload: textStatus,
                    meta: {
                        headers: {
                            [constants.HEADER_ERROR]: jqXHR.getResponseHeader(constants.HEADER_ERROR),
                            [constants.HEADER_SID]: jqXHR.getResponseHeader(constants.HEADER_SID),
                            [constants.HEADER_DID]: jqXHR.getResponseHeader(constants.HEADER_DID),
                        }
                    }
                });
                resolve({
                    payload: textStatus,
                    status: jqXHR.status
                });
            };
            // set.complete = function(jqXHR, textStatus){
            //
            // };
            $.ajax(set);
        });

        const forbiddenRequest = async () => {
            return await dispatch({
                type: constants.REQUEST_FORBIDDEN,
                payload: 'Forbidden'
            });
        };

        // Request
        let response = await call();

        if(response.status === 401 || response.status === 403)
        {
            // relogin
            response = await new Promise((resolve, reject) => {
                let set = {
                    method: 'POST',
                    url: CONFIG.api + '/auth',
                    dataType: 'json',
                    headers: constructHeaders({sid: false, did: true}),
                    crossDomain: true
                };

                set.success = function(data, textStatus, jqXHR){
                    dispatch({
                        type: constants.LOGIN_SUCCESS,
                        payload: data,
                        meta: {
                            headers: {
                                [constants.HEADER_ERROR]: jqXHR.getResponseHeader(constants.HEADER_ERROR),
                                [constants.HEADER_SID]: jqXHR.getResponseHeader(constants.HEADER_SID),
                                [constants.HEADER_DID]: jqXHR.getResponseHeader(constants.HEADER_DID),
                            }
                        }
                    });
                    resolve({
                        payload: data,
                        status: jqXHR.status
                    });
                };
                set.error = function(jqXHR, textStatus, errorThrown){
                    dispatch({
                        type: constants.LOGIN_FAILURE,
                        payload: textStatus,
                        meta: {
                            headers: {
                                [constants.HEADER_ERROR]: jqXHR.getResponseHeader(constants.HEADER_ERROR),
                                [constants.HEADER_SID]: jqXHR.getResponseHeader(constants.HEADER_SID),
                                [constants.HEADER_DID]: jqXHR.getResponseHeader(constants.HEADER_DID),
                            }
                        }
                    });
                    reject({
                        payload: textStatus,
                        status: jqXHR.status
                    });
                };
                $.ajax(set);
            });

            if(!response.payload?.success || response.status === 401 || response.status === 403)
                return await forbiddenRequest();

            //redo request
            response = await call();

            if(response.status === 401 || response.status === 403)
                return await forbiddenRequest();
        }

        return response;
    };
}

export function login(data)
{
    return COMM('/auth', constants.LOGIN, {
        data
    }, {
        sid: false,
        did: true
    });
}

export function logout()
{
    return COMM('/logout', constants.LOGOUT, {
        method: 'GET'
    }, {
        did: true
    });
}

export function getUser()
{
    return COMM('/user', constants.GET_USER, {
        method: 'GET'
    });
}

export function updateUser(id, data)
{
    return COMM('/users/' + id, constants.UPDATE_USER, {
        method: 'PATCH',
        data
    }, {
        did: !!data.password
    });
}

export function resendVarificationMail()
{
    return COMM('/user/verification/resend', constants.RESEND_VERIFICATION_MAIL, {
        method: 'GET'
    });
}

export function validateMail(data)
{
    return COMM('/validate', constants.VALIDATE_MAIL, {
        method: 'GET',
        data
    }, {
        sid: false,
        did: false
    });
}

export function resetMail(data)
{
    return COMM('/user/email/reset', constants.RESET_MAIL, {
        method: 'GET',
        data
    }, {
        sid: false,
        did: false
    });
}

export function passwordRecover(data)
{
    return COMM('/user/password/recover', constants.PASSWORD_RECOVER, {
        method: 'POST',
        data
    }, {
        sid: false,
        did: false
    });
}

export function passwordReset(data)
{
    return COMM('/user/password/recover', constants.RESEND_VERIFICATION_MAIL, {
        method: 'GET',
        data
    }, {
        sid: false,
        did: false
    });
}

export function registerInstitute(data)
{
    return COMM('/users', constants.CREATE_INSTITUTE, {
        data
    }, {
        sid: false,
        did: false
    });
}

export function updateInstitute(data)
{
    return COMM('/institutes', constants.UPDATE_INSTITUTE, {
        method: 'PATCH',
        data
    });
}

export function getInstitute()
{
    return COMM('/institutes', constants.GET_INSTITUTE, {
        method: 'GET'
    });
}

export function uploadInstituteContractTemplate(data)
{
    const formData = data;
    data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    return COMM('/institutes/contract', constants.UPLOAD_INSTITUTE_CONTRACT_TEMPLATE, {
        method: 'POST',
        data,
        processData: false,
        contentType: false,
    });
}

export function removeInstituteContractTemplate()
{
    return COMM('/institutes/contract', constants.REMOVE_INSTITUTE_CONTRACT_TEMPLATE, {
        method: 'DELETE',
    });
}

export function getInstituteInstructors()
{
    return COMM('/instructors', constants.GET_INSTITUTE_INSTRUCTORS, {
        method: 'GET'
    });
}

export function addInstructor(data)
{
    return COMM('/instructors', constants.ADD_INSTRUCTOR, {
        method: 'POST',
        data
    });
}

export function removeInstructor(id)
{
    return COMM('/instructors/' + id, constants.REMOVE_INSTRUCTOR, {
        method: 'DELETE'
    });
}

export function checkInstructorHash(data)
{
    return COMM('/instructors/init', constants.CHECK_INSTRUCTOR_HASH, {
        method: 'GET',
        data
    }, {
        sid: false,
        did: true
    });
}

export function initIntructor(data)
{
    return COMM('/instructors/init', constants.INIT_INSTRUCTOR, {
        method: 'POST',
        data
    }, {
        sid: false,
        did: true
    });
}

export function updateInstructor(data)
{
    return COMM('/instructors', constants.UPDATE_INSTRUCTOR, {
        method: 'PATCH',
        data
    });
}

export function addCourse(data)
{
    return COMM('/courses', constants.ADD_COURSE, {
        method: 'POST',
        data
    });
}

export function getCourses()
{
    return COMM('/courses', constants.GET_COURSES, {
        method: 'GET'
    });
}

export function getCourse(id)
{
    return COMM('/course/' + id, constants.GET_COURSE, {
        method: 'GET'
    });
}

export function updateCourse(id, data)
{
    return COMM('/course/' + id, constants.UPDATE_COURSE, {
        method: 'PATCH',
        data
    });
}

export function removeCourse(id)
{
    return COMM('/course/' + id, constants.REMOVE_COURSE, {
        method: 'DELETE'
    });
}

export function createCycle(data)
{
    return COMM('/cycles', constants.CREATE_CYCLE, {
        method: 'POST',
        data
    });
}

export function getCycle(id)
{
    return COMM('/cycle/' + id, constants.GET_CYCLE, {
        method: 'GET'
    });
}

export function updateCycle(id, data)
{
    return COMM('/cycle/' + id, constants.UPDATE_CYCLE, {
        method: 'PATCH',
        data
    });
}

export function unlockCycleRatings(id)
{
    return COMM('/cycle/' + id + '/ratings/unlock', constants.UNLOCK_CYCLE_RATINGS, {
        method: 'POST'
    });
}

export function downloadCyclePDF(id)
{
    return COMM('/cycle/' + id + '/pdf', constants.PDF_CYCLE, {
        method: 'GET'
    });
}

export function studentFilesClear()
{
    return {
        type: constants.CLEAR_STUDENT_FILES
    }
}

export function studentFilesUpload(id, data)
{
    const formData = data;
    data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    return COMM('/students/' + id + '/files', constants.UPLOAD_STUDENT_FILES, {
        method: 'POST',
        data,
        processData: false,
        contentType: false,
    });
}

export function studentFilesGet(id)
{
    return COMM('/students/' + id + '/files', constants.GET_STUDENT_FILES, {
        method: 'GET'
    });
}

export function studentFilesRemove(id, data)
{
    return COMM('/students/' + id + '/files', constants.REMOVE_STUDENT_FILE, {
        method: 'DELETE',
        data
    });
}

export function getPartners()
{
    return COMM('/partners', constants.GET_PARTNERS, {
        method: 'GET'
    });
}

export function addPartner(data)
{
    const formData = data;
    data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    return COMM('/partners', constants.ADD_PARTNER, {
        method: 'POST',
        data,
        processData: false,
        contentType: false,
    });
}

export function updatePartner(id, data)
{
    const formData = data;
    data = new FormData();
    Object.keys(formData).forEach(key => data.append(key, formData[key]));
    return COMM('/partners/' + id, constants.UPDATE_PARTNER, {
        method: 'PATCH',
        data,
        processData: false,
        contentType: false,
    });
}

export function removePartner(id)
{
    return COMM('/partners/' + id, constants.REMOVE_PARTNER, {
        method: 'DELETE',
    });
}

export function checkRatingsHash(data)
{
    return COMM('/ratings', constants.CHECK_RATINGS_HASH, {
        method: 'GET',
        data
    }, {
        sid: false,
        did: false
    });
}

export function addRatings(data)
{
    return COMM('/ratings', constants.ADD_RATINGS, {
        method: 'POST',
        data
    }, {
        sid: false,
        did: false
    });
}

export function getPracticeOptions()
{
    return COMM('/practices/options', constants.GET_PRACTICE_OPTIONS, {
        method: 'GET'
    }, {
        sid: false,
        did: false
    });
}

export function getInstitutes(pattern)
{
    return COMM('/institutes/search', constants.GET_INSTITUTES, {
        method: 'GET',
        data: {
            pattern
        }
    });
}

// ==== User settings ====
export function setBackgroundImage(id) {
    return {
        type: constants.SET_BACKGROUND_IMAGE,
        id
    }
}

export function setActiveCourse(id) {
    return {
        type: constants.SET_ACTIVE_COURSE,
        id
    }
}

export function setActivePartner(id) {
    return {
        type: constants.SET_ACTIVE_PARTNER,
        id
    }
}
