import React from 'react';

import { useDispatch } from 'react-redux'
import { logout } from '../Actions';

export default (props) => {
    const dispatch = useDispatch();

    return (
        <div className="content flex login error-page">
            <div className="flex__item--50 crop">
                <a href="/"><img src="/img/logo-white.svg" className="logo" alt="Vipana Logo" title="vipana" /></a>
                <img src="/img/login.jpg" className="img--half" alt="Eine Person die an einem Tablet arbeitet" title="Vipana – Login" />
            </div>
            <div className="flex__item--50 box">
                <div className="rel">
                    <h1>Fehler</h1>
                    {
                        {
                            'logout_required': <><span>Sie sind noch angemeldet. Bitte melden Sie sich zuerst ab.</span><button className="btn" onClick={() => dispatch(logout())}>Abmelden</button></>
                        }[props.status]
                    }
                </div>
                <footer className="subpages">
                    <div className="container">
                        <p><a href="/impressum/">Impressum</a> <a href="/datenschutz/">Datenschutz</a></p>
                    </div>
                </footer>
            </div>
        </div>
    );
};
