import * as constants from '../Actions/constants';

export default (state = {}, action) => {
    switch(action.type)
    {
        case constants.CLEAR_STUDENT_FILES:
            delete state.list;
            return {
                ...state
            };
        case constants.GET_STUDENT_FILES_SUCCESS:
            return {
                ...state,
                list: action.payload.rows
            };
        case constants.UPLOAD_STUDENT_FILES_SUCCESS:
            return {
                ...state,
                list: [
                    ...state.list,
                    ...Object.values(action.payload)
                ]
            };
        case constants.REMOVE_STUDENT_FILE_SUCCESS:
            if(action.payload.success)
                for(let i = 0; i < state.list.length; i++)
                    if(state.list[i].id === action.payload.id)
                    {
                        state.list.splice(i, 1);
                        break;
                    }
            return {
                ...state,
            };
        default:
            return state;
    }
}
