import React, {useEffect} from 'react';
import { Router, Redirect } from '@reach/router';
import Loadable from 'react-loadable';
import Loading from './Loading';
import Error from './Error';
import CONFIG from '../config-APP_TARGET';
import { connect } from 'react-redux';

const LoadableInstitute = Loadable({
    loader: () => import('./Institute'),
    loading: Loading,
});

const LoadableInstructor = Loadable({
    loader: () => import('./Instructor'),
    loading: Loading,
});

const LoadableInstructorDashboard = Loadable({
    loader: () => import('./Instructor/Dashboard'),
    loading: Loading,
});

const LoadableInstructorProfile = Loadable({
    loader: () => import('./Instructor/Profile'),
    loading: Loading,
});

const LoadableCourses = Loadable({
    loader: () => import('./Instructor/Courses/Courses'),
    loading: Loading,
});

const LoadablePartners = Loadable({
    loader: () => import('./Instructor/Partners'),
    loading: Loading,
});

const LoadableLogin = Loadable({
    loader: () => import('./Login'),
    loading: Loading,
});
const LoadableRegister = Loadable({
    loader: () => import('./Register'),
    loading: Loading,
});

const LoadableRegisterInstructor = Loadable({
    loader: () => import('./RegisterInstructor'),
    loading: Loading,
});

const LoadablePasswordReset = Loadable({
    loader: () => import('./PasswordReset'),
    loading: Loading,
});

const LoadableEMailActions = Loadable({
    loader: () => import('./EMailActions'),
    loading: Loading,
});

const LoadableStudentRating = Loadable({
    loader: () => import('./Ratings/StudentRating'),
    loading: Loading,
});

const LoadablePracticeRating = Loadable({
    loader: () => import('./Ratings/PracticeRating'),
    loading: Loading,
});

const LoadableContractOverview = Loadable({
    loader: () => import('./ContractOverview'),
    loading: Loading,
});


const PrivateRoute = (props) => {
    if(props.user?.id && props.user?.role === props.role) {
        const Component = props.component;
        return <Component {...props} />
    }else if(props.user?.id) {
       window.location.pathname = "/";
    }
    return <Redirect from={props.path} to={props.path+"/login"} noThrow/>
};

const PublicOnlyRoute = (props) => {
    if(props.user?.id) {
        return props.user?.role === props.role
            ? <Redirect from={props.path} to={props.path.replace("/login", "").replace("/registrieren", "")} noThrow />
            : <Error status="logout_required" />//(window.location.pathname = '/');
    }
    const Component = props.component;
    return <Component {...props} />

};

const Init = (props) => {
    if(props.user?.id && !props.user?.role) {
        return (<Loading />);
    }
    return (
        <Router>
            {/*<Register path="/schulen/registrieren" role={CONFIG.roles.institute} />*/}
            <PrivateRoute path="/schulen" component={LoadableInstitute} user={props.user} role={CONFIG.roles.institute} />
            <PublicOnlyRoute path="/schulen/login" role={CONFIG.roles.institute} component={LoadableLogin} user={props.user} />
            <PublicOnlyRoute path="/schulen/registrieren" role={CONFIG.roles.institute} component={LoadableRegister} user={props.user} />
            <PrivateRoute path="/lehrer" component={LoadableInstructor} user={props.user} role={CONFIG.roles.instructor}>
                <LoadableInstructorDashboard path="/" />
                <LoadableInstructorProfile path="profil" />
                <LoadableCourses path="klassen" />
                <LoadablePartners path="kooperation" />
            </PrivateRoute>
            <PublicOnlyRoute path="/lehrer/login" role={CONFIG.roles.instructor} component={LoadableLogin} user={props.user} />
            <PublicOnlyRoute path="/lehrer/registrieren" role={CONFIG.roles.instructor} component={LoadableRegisterInstructor} user={props.user} />
            <LoadablePasswordReset path="/passwort" />
            <LoadablePasswordReset path="/passwort/zuruecksetzen" />
            <LoadableEMailActions path="/email/validieren" />
            <LoadableEMailActions path="/email/zuruecksetzen" />
            <LoadableStudentRating path="/bewertung/praktikant" />
            <LoadablePracticeRating path="/bewertung/praxis" />
            <PublicOnlyRoute path="/vertraege/login" role={CONFIG.roles.practice} component={LoadableLogin} user={props.user} />
            <PrivateRoute path="/vertraege" component={LoadableContractOverview} user={props.user} role={CONFIG.roles.practice} />
        </Router>
    );
};

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        user: state.user,
    }
};

export default connect(mapStateToProps)(Init);
