import * as constants from '../Actions/constants';

export default (state = {}, action) => {
    switch(action.type) {
        case constants.GET_PARTNERS_SUCCESS:
            const partners = {};
            action.payload.rows.forEach(partner => (partners[partner.id] = partner));
            return {
                ...state,
                list: partners
            };
        case constants.ADD_PARTNER_SUCCESS:
        case constants.UPDATE_PARTNER_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: action.payload
                }
            };
        case constants.REMOVE_PARTNER_SUCCESS:
            if(action.payload.id !== undefined)
                delete state.list[action.payload.id];
            return {
                ...state
            };
        default:
            return state;
    }
}
