import * as constants from '../Actions/constants';

export default (state = {}, action) => {
    switch(action.type) {
        case constants.LOGIN_SUCCESS:
        case constants.GET_USER_SUCCESS:
            return {
                ...state,
                ...(action.payload.success || action.payload.id
                    ? { ...action.payload }
                    : {}),
                sid: action.meta.headers[constants.HEADER_SID] || state.sid,
                did: action.meta.headers[constants.HEADER_DID] || state.did,
            };
        case constants.LOGIN_FAILURE:
            return {
                ...state,
                // user: {
                //     ...action.payload
                // }
            };
        case constants.REQUEST_FORBIDDEN:
        case constants.LOGOUT_SUCCESS:
            return {
                did: state.did
            };
        case constants.UPDATE_USER_SUCCESS:
            return {
                ...(action.payload.error ? state : {...state, ...action.payload})
            };
        case constants.UPDATE_INSTITUTE_SUCCESS:
            return {
                ...state,
                Institute: action.payload.error ? state.Institute : action.payload
            };
        case constants.UPLOAD_INSTITUTE_CONTRACT_TEMPLATE_SUCCESS:
            return {
                ...state,
                Institute: {
                    ...state.Institute,
                    contractTemplate: action.payload.key || null
                }
            };
        case constants.REMOVE_INSTITUTE_CONTRACT_TEMPLATE_SUCCESS:
            return {
                ...state,
                Institute: {
                    ...state.Institute,
                    contractTemplate: action.payload.success ? null : state.Institute.contractTemplate
                }
            };
        case constants.GET_INSTITUTE_SUCCESS:
            return {
                ...state,
                currentInstitute: action.payload,
            };
        case constants.UPDATE_INSTRUCTOR_SUCCESS:
            return {
                ...state,
                Instructor: action.payload.error ? state.Instructor : action.payload
            };

        case constants.GET_INSTITUTE_INSTRUCTORS_SUCCESS:
            return {
                ...state,
                Institute: {
                    ...state.Institute,
                    Instructors: action.payload.error ? null : action.payload
                }

            };
        case constants.REMOVE_INSTRUCTOR_SUCCESS:
            let len;
            if(action.payload.id && (len = state.Institute?.Instructors?.rows.length))
            {
                for(let i = 0; i < len; i++)
                    if(state.Institute?.Instructors?.rows[i].id === action.payload.id)
                    {
                        state.Institute?.Instructors?.rows.splice(i, 1);
                        break;
                    }
            }
            return { ...state };
        case constants.ADD_COURSE_SUCCESS:
            if(action.payload?.error)
                return state;
            return {
                ...state,
                courses: [...(state.courses ?? []), action.payload]
            };
        case constants.UPDATE_COURSE_SUCCESS: {
            const updatedCourses = [...state.courses];
            const courseIndex = updatedCourses.findIndex(c => c.id === action.payload.id);
            updatedCourses[courseIndex] = action.payload;
            return {
                ...state,
                courses: updatedCourses,
            }
        }
        case constants.GET_COURSES_SUCCESS:
            return {
                ...state,
                courses: [...(action.payload?.rows ?? [])]
            };
        case constants.GET_COURSE_SUCCESS:
            let CourseActiveCycle = null;
            let CourseCycles = action.payload.CourseCycles;
            const currentDate = new Date();
            // for(let i = 0; i < CourseCycles.length; i++)
            //     if(new Date(CourseCycles[i].to) >= currentDate)
            //     {
            //         CourseActiveCycle = CourseCycles.splice(i, 1)[0];
            //         break;
            //     }
            if(CourseCycles.length && new Date(CourseCycles[0].to) >= currentDate) // the first (newest 'to') cycle should be the active one... otherwise there is no active cycle
                CourseActiveCycle = CourseCycles.splice(0, 1)[0];
            return {
                ...state,
                Courses: {
                    ...state.Courses,
                    [action.payload.id]: {
                        ...action.payload,
                        CourseActiveCycle,
                        CourseCycles
                    }
                }
            };
        case constants.REMOVE_COURSE_SUCCESS:
            if(action.payload.success)
            {
                delete state.Courses?.[action.payload.id];
                for(let i = 0; i < state.courses.length; i++)
                    if(state.courses[i].id === action.payload.id)
                        state.courses.splice(i, 1);
            }

            return {
                ...state,
                courses: [
                    ...state.courses
                ],
                Courses: {
                    ...state.Courses
                }
            };
        case constants.CREATE_CYCLE_SUCCESS:
        case constants.UPDATE_CYCLE_SUCCESS:
            return {
                ...state ,
                Courses: {
                    ...state.Courses,
                    [action.payload.CourseId]: {
                        ...state.Courses[action.payload.CourseId],
                        CourseActiveCycle: action.payload,
                    }
                }
            };
        case constants.GET_CYCLE_SUCCESS:
            if(!action.payload.error)
                return {
                    ...state,
                    cycles: {
                        ...(state.cycles ?? {}),
                        [action.payload.id]: action.payload
                    }
                };
            return state;
        case constants.UNLOCK_CYCLE_RATINGS_SUCCESS:
            if(!action.payload.error)
            {
                Object.values(state.Courses).some(course => {
                    if(!course.CourseActiveCycle && !course.CourseCycles.length)
                        return false;

                    return [
                        course.CourseActiveCycle,
                        ...course.CourseCycles
                    ].some(cycle => {
                       if(cycle.id === action.payload.id)
                       {
                           cycle.ratingsUnlocked = 1;
                           return true;
                       }
                    });
                });

                const cycle = state?.cycles?.[action.payload.id];
                if(cycle)
                    cycle.ratingsUnlocked = 1;

                return {
                    ...state
                };
            }
            return state;
        default:
            return state;
    }
}
