import * as constants from './Actions/constants';
import {getUser} from './Actions';

export const syncUserProfile = ({ dispatch, getState }) => next => action => {
    if((action.type === constants.LOGIN_SUCCESS && action.payload?.success) || (action.type === 'persist/REHYDRATE' && action.payload?.user?.sid)) {
        const res = next(action);
        dispatch(getUser());
        return res;
    }
    return next(action);
};
