const CONFIG = {
    api: 'https://api.vipana.de',
    roles: {
        institute: 42641621,
        instructor: 68501672,
        practice: 81982163
    },
    filesBucket: 'https://files.vipana.de/'
};

export default CONFIG;
