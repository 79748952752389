import { combineReducers } from 'redux'

import user from './user';
import settings from './settings';
import partners from './partners';
import files from './files';

export default combineReducers({
    user,
    settings,
    partners,
    files
})
