//import CONFIG from './config';
//import {getUser, updateUser, registerInstitute, updateInstitute} from './Actions';
import Init from './Pages/Init';
import Loading from './Pages/Loading';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './Reducers';
import { syncUserProfile } from './storeMiddleware';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'
import thunk from 'redux-thunk';

const initRoute = route => {
    const persistConfig = {
        key: 'root',
        storage,
    };

    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(thunk, syncUserProfile)));
    const persistor = persistStore(store);
    const page = {
        component: Init,
        props: {
            route
        }
    };

    const Element = page.component;
    ReactDOM.render(<Provider store={store}><PersistGate loading={<Loading/>} persistor={persistor}><Element {...page.props} /></PersistGate></Provider>, document.querySelector('#main'));
};

if(window.location.host === 'vipana.de')
    window.location.host = 'www.vipana.de';
else
{
    document.addEventListener('DOMContentLoaded', function(e){
        initRoute(window.location.pathname);
    });
}
