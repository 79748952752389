import * as constants from '../Actions/constants';

const initalState = {
    backgroundId: 1,
    activeCourseId: null,
    practiceOptions: null,
};

export default (state = initalState, action) => {
    switch(action.type) {
        case constants.SET_BACKGROUND_IMAGE:
            return {
                ...state,
                backgroundId: action.id,
            };
        case constants.SET_ACTIVE_COURSE:
            return {
                ...state,
                activeCourseId: action.id,
            };
        case constants.SET_ACTIVE_PARTNER:
            return {
                ...state,
                activePartnerId: action.id,
            };
        case constants.GET_PRACTICE_OPTIONS_SUCCESS:
            return {
                ...state,
                practiceOptions: action.payload,
            }
        default:
            return state;
    }
}
