
export const REQUEST = 'request';
export const REQUEST_FORBIDDEN = 'request_forbidden';

export const HEADER_ERROR = 'X-API-Error';
export const HEADER_SID = 'X-SID';
export const HEADER_DID = 'X-DID';

// TYPES
export const SUCCESS = '_success';
export const FAILURE = '_failure';

export const LOGIN = 'login';
export const LOGIN_SUCCESS = LOGIN + SUCCESS;
export const LOGIN_FAILURE = LOGIN + FAILURE;

export const LOGOUT = 'logout';
export const LOGOUT_SUCCESS = LOGOUT + SUCCESS;
export const LOGOUT_FAILURE = LOGOUT + FAILURE;

export const GET_USER = 'get_user';
export const GET_USER_SUCCESS = GET_USER + SUCCESS;
export const GET_USER_FAILURE = GET_USER + FAILURE;

export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SUCCESS = UPDATE_USER + SUCCESS;
export const UPDATE_USER_FAILURE = UPDATE_USER + FAILURE;

export const RESEND_VERIFICATION_MAIL = 'resend_verification_mail';
export const RESEND_VERIFICATION_MAIL_SUCCESS = RESEND_VERIFICATION_MAIL + SUCCESS;
export const RESEND_VERIFICATION_MAIL_FAILURE = RESEND_VERIFICATION_MAIL + FAILURE;

export const VALIDATE_MAIL = 'validate_mail';
export const VALIDATE_MAIL_SUCCESS = VALIDATE_MAIL + SUCCESS;
export const VALIDATE_MAIL_FAILURE = VALIDATE_MAIL + FAILURE;

export const RESET_MAIL = 'reset_mail';
export const RESET_MAIL_SUCCESS = RESET_MAIL + SUCCESS;
export const RESET_MAIL_FAILURE = RESET_MAIL + FAILURE;

export const PASSWORD_RECOVER = 'password_recover';
export const PASSWORD_RECOVER_SUCCESS = PASSWORD_RECOVER + SUCCESS;
export const PASSWORD_RECOVER_FAILURE = PASSWORD_RECOVER + FAILURE;

export const PASSWORD_RESET = 'password_reset';
export const PASSWORD_RESET_SUCCESS = PASSWORD_RESET + SUCCESS;
export const PASSWORD_RESET_FAILURE = PASSWORD_RESET + FAILURE;

// INSTITUTE
export const CREATE_INSTITUTE = 'create_institute';
export const CREATE_INSTITUTE_SUCCESS = CREATE_INSTITUTE + SUCCESS;
export const CREATE_INSTITUTE_FAILURE = CREATE_INSTITUTE + FAILURE;

export const GET_INSTITUTE = 'get_institute';
export const GET_INSTITUTE_SUCCESS = GET_INSTITUTE + SUCCESS;
export const GET_INSTITUTE_FAILURE = GET_INSTITUTE * FAILURE;

export const UPDATE_INSTITUTE = 'update_institute';
export const UPDATE_INSTITUTE_SUCCESS = UPDATE_INSTITUTE + SUCCESS;
export const UPDATE_INSTITUTE_FAILURE = UPDATE_INSTITUTE + FAILURE;

export const UPLOAD_INSTITUTE_CONTRACT_TEMPLATE = 'upload_institute_contract_template';
export const UPLOAD_INSTITUTE_CONTRACT_TEMPLATE_SUCCESS = UPLOAD_INSTITUTE_CONTRACT_TEMPLATE + SUCCESS;
export const UPLOAD_INSTITUTE_CONTRACT_TEMPLATE_FAILURE = UPLOAD_INSTITUTE_CONTRACT_TEMPLATE + FAILURE;

export const REMOVE_INSTITUTE_CONTRACT_TEMPLATE = 'remove_institute_contract_template';
export const REMOVE_INSTITUTE_CONTRACT_TEMPLATE_SUCCESS = REMOVE_INSTITUTE_CONTRACT_TEMPLATE + SUCCESS;
export const REMOVE_INSTITUTE_CONTRACT_TEMPLATE_FAILURE = REMOVE_INSTITUTE_CONTRACT_TEMPLATE + FAILURE;

export const GET_INSTITUTE_INSTRUCTORS = 'get_institute_instructors';
export const GET_INSTITUTE_INSTRUCTORS_SUCCESS = GET_INSTITUTE_INSTRUCTORS + SUCCESS;
export const GET_INSTITUTE_INSTRUCTORS_FAILURE = GET_INSTITUTE_INSTRUCTORS + FAILURE;

// INSTRUCTOR
export const ADD_INSTRUCTOR = 'add_instructor';
export const ADD_INSTRUCTOR_SUCCESS = ADD_INSTRUCTOR + SUCCESS;
export const ADD_INSTRUCTOR_FAILURE = ADD_INSTRUCTOR + FAILURE;

export const REMOVE_INSTRUCTOR = 'remove_instructor';
export const REMOVE_INSTRUCTOR_SUCCESS = REMOVE_INSTRUCTOR + SUCCESS;
export const REMOVE_INSTRUCTOR_FAILURE = REMOVE_INSTRUCTOR + FAILURE;

export const CHECK_INSTRUCTOR_HASH = 'check_instructor_hash';
export const CHECK_INSTRUCTOR_HASH_SUCCESS = CHECK_INSTRUCTOR_HASH + SUCCESS;
export const CHECK_INSTRUCTOR_HASH_FAILURE = CHECK_INSTRUCTOR_HASH + FAILURE;

export const INIT_INSTRUCTOR = 'init_instructor';
export const INIT_INSTRUCTOR_SUCCESS = INIT_INSTRUCTOR + SUCCESS;
export const INIT_INSTRUCTOR_FAILURE = INIT_INSTRUCTOR + FAILURE;

export const UPDATE_INSTRUCTOR = 'update_instructor';
export const UPDATE_INSTRUCTOR_SUCCESS = UPDATE_INSTRUCTOR + SUCCESS;
export const UPDATE_INSTRUCTOR_FAILURE = UPDATE_INSTRUCTOR + FAILURE;

//COURSES
export const ADD_COURSE = 'add_course';
export const ADD_COURSE_SUCCESS = ADD_COURSE + SUCCESS;
export const ADD_COURSE_FAILURE = ADD_COURSE + FAILURE;

export const GET_COURSES = 'get_courses';
export const GET_COURSES_SUCCESS = GET_COURSES + SUCCESS;
export const GET_COURSES_FAILURE = GET_COURSES * FAILURE;

export const GET_COURSE = 'get_course';
export const GET_COURSE_SUCCESS = GET_COURSE + SUCCESS;
export const GET_COURSE_FAILURE = GET_COURSE + FAILURE;

export const UPDATE_COURSE = 'update_course';
export const UPDATE_COURSE_SUCCESS = UPDATE_COURSE + SUCCESS;
export const UPDATE_COURSE_FAILURE = UPDATE_COURSE + FAILURE;

export const REMOVE_COURSE = 'remove_course';
export const REMOVE_COURSE_SUCCESS = REMOVE_COURSE + SUCCESS;
export const REMOVE_COURSE_FAILURE = REMOVE_COURSE + FAILURE;

export const CREATE_CYCLE = 'create_cycle';
export const CREATE_CYCLE_SUCCESS = CREATE_CYCLE + SUCCESS;
export const CREATE_CYCLE_FAILURE = CREATE_CYCLE + FAILURE;

export const GET_CYCLE = 'get_cycle';
export const GET_CYCLE_SUCCESS = GET_CYCLE + SUCCESS;
export const GET_CYCLE_FAILURE = GET_CYCLE + FAILURE;

export const UPDATE_CYCLE = 'update_cycle';
export const UPDATE_CYCLE_SUCCESS = UPDATE_CYCLE + SUCCESS;
export const UPDATE_CYCLE_FAILURE = UPDATE_CYCLE + FAILURE;

export const PDF_CYCLE = 'pdf_cycle';
export const PDF_CYCLE_SUCCESS = PDF_CYCLE + SUCCESS;
export const PDF_CYCLE_FAILURE = PDF_CYCLE + FAILURE;

//STUDENTS
export const CLEAR_STUDENT_FILES = 'clear_student_files';

export const UPLOAD_STUDENT_FILES = 'upload_student_files';
export const UPLOAD_STUDENT_FILES_SUCCESS = UPLOAD_STUDENT_FILES + SUCCESS;
export const UPLOAD_STUDENT_FILES_FAILURE = UPLOAD_STUDENT_FILES + FAILURE;

export const GET_STUDENT_FILES = 'get_student_files';
export const GET_STUDENT_FILES_SUCCESS = GET_STUDENT_FILES + SUCCESS;
export const GET_STUDENT_FILES_FAILURE = GET_STUDENT_FILES + FAILURE;

export const REMOVE_STUDENT_FILE = 'remove_student_file';
export const REMOVE_STUDENT_FILE_SUCCESS = REMOVE_STUDENT_FILE + SUCCESS;
export const REMOVE_STUDENT_FILE_FAILURE = REMOVE_STUDENT_FILE + FAILURE;

//PARTNERS
export const GET_PARTNERS = 'get_partners';
export const GET_PARTNERS_SUCCESS = GET_PARTNERS + SUCCESS;
export const GET_PARTNERS_FAILURE = GET_PARTNERS + FAILURE;

export const ADD_PARTNER = 'add_partner';
export const ADD_PARTNER_SUCCESS = ADD_PARTNER + SUCCESS;
export const ADD_PARTNER_FAILURE = ADD_PARTNER + FAILURE;

export const UPDATE_PARTNER = 'update_partner';
export const UPDATE_PARTNER_SUCCESS = UPDATE_PARTNER + SUCCESS;
export const UPDATE_PARTNER_FAILURE = UPDATE_PARTNER + FAILURE;

export const REMOVE_PARTNER = 'remove_partner';
export const REMOVE_PARTNER_SUCCESS = REMOVE_PARTNER + SUCCESS;
export const REMOVE_PARTNER_FAILURE = REMOVE_PARTNER + FAILURE;

//RATINGS
export const CHECK_RATINGS_HASH = 'check_ratings_hash';
export const CHECK_RATINGS_HASH_SUCCESS = CHECK_RATINGS_HASH + SUCCESS;
export const CHECK_RATINGS_HASH_FAILURE = CHECK_RATINGS_HASH + FAILURE;

export const ADD_RATINGS = 'add_ratings';
export const ADD_RATINGS_SUCCESS = ADD_RATINGS + SUCCESS;
export const ADD_RATINGS_FAILURE = ADD_RATINGS + FAILURE;

export const UNLOCK_CYCLE_RATINGS = 'unlock_cycle_ratings';
export const UNLOCK_CYCLE_RATINGS_SUCCESS = UNLOCK_CYCLE_RATINGS + SUCCESS;
export const UNLOCK_CYCLE_RATINGS_FAILURE = UNLOCK_CYCLE_RATINGS + FAILURE;

//PRACTICE OPTIONS
export const GET_PRACTICE_OPTIONS = 'get_practice_options';
export const GET_PRACTICE_OPTIONS_SUCCESS = GET_PRACTICE_OPTIONS + SUCCESS;
export const GET_PRACTICE_OPTIONS_FAILURE = GET_PRACTICE_OPTIONS + FAILURE;

//PRACTICE INSTITUTE SEARCH
export const GET_INSTITUTES = 'get_institutes';
export const GET_INSTITUTES_SUCCESS = GET_INSTITUTES + SUCCESS;
export const GET_INSTITUTES_FAILURE = GET_INSTITUTES + FAILURE;

//USER SETTINGS
export const SET_BACKGROUND_IMAGE = 'set_background_image';
export const SET_ACTIVE_COURSE = 'set_active_course';
export const SET_ACTIVE_PARTNER = 'set_active_partner';
