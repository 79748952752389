import React from 'react';

export default (props) => {
    if(props.error) {
        console.log(props.error);
        return <div>Fehler! <button onClick={ props.retry }>Neu laden</button></div>;
    } else {
        return (
            <div className="loading flex">
                <span>
                    <svg className="spinner" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="12" strokeLinecap="round" cx="40" cy="40" r="34"></circle>
                    </svg>
                </span>
            </div>
        );
    }
};
